import * as Sentry from "@sentry/remix";
import { useEffect, useMemo } from "react";

export function useReplayInstance() {
  return useMemo(() => Sentry.getCurrentHub().getClient()?.getIntegration(Sentry.Replay), []);
}

export function useFlushReplayOnVisibilityChange() {
  const replay = useReplayInstance();

  useEffect(() => {
    function getSentryReplaySession(): any | undefined {
      try {
        return JSON.parse(sessionStorage.getItem("sentryReplaySession")!) as any;
      } catch {
        return undefined;
      }
    }

    async function onStop() {
      if (!replay) return;
      if (typeof document === "undefined") return;

      const session = getSentryReplaySession();
      if (!session) return;
      if (session.sampled === "session") await replay?.flush();
    }

    document.addEventListener("visibilitychange", onStop);
    return () => document.removeEventListener("visibilitychange", onStop);
  }, [replay]);
}
