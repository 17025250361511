import * as Sentry from "@sentry/remix";
import type { ReactNode } from "react";
import { createContext, useCallback, useContext, useEffect } from "react";

import { useAuth } from "~/hooks/use-auth";

import {
  useFlushReplayOnVisibilityChange,
  useReplayInstance,
} from "../hooks/use-flush-replay-on-visibility-change";

const ReplayContext = createContext<{ startRecording: () => void }>({
  startRecording: () => {},
});

export function ReplayProvider({ children }: { children: ReactNode }) {
  const auth = useAuth();
  const replay = useReplayInstance();

  const recordingFeatureFlagEnabled = auth?.user?.featureFlags?.includes("record");

  const startRecording = useCallback(async () => {
    try {
      if (typeof document === "undefined") return;
      if (!replay) return;
      const options = Sentry.getCurrentHub().getClient()?.getOptions();
      if (!options) return;
      // @ts-expect-error
      options.replaysSessionSampleRate = 1;
      await replay.stop();
      replay.start();
    } catch (error) {
      console.error(error);
    }
  }, [replay]);

  useEffect(() => {
    if (!replay) return;
    if (!recordingFeatureFlagEnabled) return;
    startRecording()
      .then(() => {})
      .catch(() => {});
  }, [replay, startRecording, recordingFeatureFlagEnabled]);

  useFlushReplayOnVisibilityChange();

  return <ReplayContext.Provider value={{ startRecording }}>{children}</ReplayContext.Provider>;
}

// this usage is okay
// eslint-disable-next-line func-style
export const useStartRecording = () => useContext(ReplayContext).startRecording;
